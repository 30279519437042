import React from "react";
import { Alert, Container } from "reactstrap";
import "./AmendmentBanner.scss";
import { BsExclamationDiamondFill,  BsFillCheckCircleFill } from "react-icons/bs";

export const AmendmentBanner = ({status, reason}) => {

    let message = ''; 
    let color='primary';
    switch(status){
        case 'Submitted':
            message = '\tData has been submitted. Validation process will be executed, after finished you will be able to review and edit forms.';
            color = 'warning';
            break;
        case 'AmendmentRequest':
            message = '\tAn amendment for this section has been submitted and is pending approval.';
            message += reason?'\t\n Amendment Reason: '+ reason:''; 
            break;
        case 'Amendment':
            message = '\tAn amendment for this section has been requested.';
            break;
        case 'ValidationPass':
            message = '\tData has been Validated. If any submitted data requires correction, you may submit an amendment request for that data from the data section.';
            break;
        case 'ValidationFailed':
            message = '\tData validation has failed, please make the required corrections and submit the data.';
            color = 'danger';
            break;
        case 'ETLComplete':
            message = '\tData has been completed for reporting year.';
            color = 'success';
            break;
            default: 
            message ='';
    }

    return(
        <>
        {message !== '' && (
            <Container >
             <Alert color={color}>
                {status ==='Completed'? (
                    <BsFillCheckCircleFill className="icon"/>):(
                    <BsExclamationDiamondFill className="icon"/>)}
                <span>{message}</span>
             </Alert>
            </Container>
           )}
      </>
    );
}
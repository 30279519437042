import { SurveyCreatorWidget } from "components/SurveyCreatorWidget";
import React, { useEffect, useRef, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Button, Col, Form, FormGroup, Input, Label } from "reactstrap";
import {
  patientDataJSON,
  staffingAndUtilizationJSON,
  dataSubmissionJSON,
  dataMeasuresJSON,
  clinicalMeasuresJSON,
  financialMeasuresJSON,
  apiGetCurrentSurveyTemplate,
} from "utilities";
import { manualDataJSON } from "utilities/surveys/manualDataJSON";

const surveySections = [
  "PatientData",
  "StaffingAndUtilization",
  "ClinicalMeasures",
  "FinancialMeasures",
  "DataMeasures",
  "ManualDataEntry",
  "DataSubmission"
];

const mapSurvey = (surveyName) => {
  switch (surveyName) {
    case "PatientData":
      return patientDataJSON;
    case "StaffingAndUtilization":
      return staffingAndUtilizationJSON;
    case "ClinicalMeasures":
      return clinicalMeasuresJSON;
    case "FinancialMeasures":
      return financialMeasuresJSON;
    case "DataMeasures":
      return dataMeasuresJSON;
    case "DataSubmission":
      return dataSubmissionJSON;
    case "ManualDataEntry":
      return manualDataJSON;
    default:
      return null;
  }
};

export const NDHCreatorTool = () => {
  const [surveyName, setSurveyName] = useState("PatientData");
  const [surveyTemplate, setSurveyTemplate] = useState({
    version: 0,
    template: undefined
  });
  const [isAdmin] = useState(true);
  const [isEditorMode, setIsEditorMode] = useState(false);
  const [shouldRerender, setShouldRerender] = useState(false);
  const [selected, setSelected] = useState(false);
  const isSaved = useRef(true);

  const toggleEditorMode = () => {
    if(!isSaved.current && isEditorMode)
    {
      if(window.confirm('You may have unsaved changes on this page. Do you want to leave this page and discard your changes?'))
        {
          setIsEditorMode(false);
          isSaved.current = true;
          setSurveyTemplate({
            version: 0,
            template: undefined
          });
        }
        setShouldRerender(false);
    }
    else
    {
      setIsEditorMode(!isEditorMode);
      setShouldRerender(!shouldRerender);
      isSaved.current = true;
      setSurveyTemplate({
        version: 0,
        template: undefined
      });
    }
  };

  const onSurveyChange = (survey) => {
    setSurveyName(survey);
    setSelected(true);
  }

  useEffect(() => {
    if(shouldRerender)
    {
      trackPromise(apiGetCurrentSurveyTemplate(surveyName,mapSurvey(surveyName)).then((response)=>
      {
        if(response)
        {
          setSurveyTemplate(response);
          setShouldRerender(false);
        }
      }))
    }
  }, [surveyName, shouldRerender]);

  return (
    <>
      <div className="d-flex justify-content-between my-3">
        <h3>NDH Survey Creator</h3>
        {isAdmin &&
          <Button
            outline
            color="primary"
            className="pill-btn"
            onClick={toggleEditorMode}
            disabled={!selected}
          >
            {!isEditorMode ? "Open" : "Close"} Form Editor
          </Button>
        }
      </div>
      {isAdmin && isEditorMode ? (
        <SurveyCreatorWidget
          surveyName={surveyName}
          template={surveyTemplate.template}
          Saved={isSaved}
        />
      ) : (
        <Col lg={8} className="m-auto">
          <Form>
            <FormGroup className="row mt-4">
              <Label sm={3} for="surveySelect" className="label">NDH Section:</Label>
              <Col sm={5}>
                <Input
                  id="surveySelect"
                  name="surveySelect"
                  type="select"
                  disabled={isEditorMode}
                  onChange={(e) => onSurveyChange(e.target.value)}
                  defaultValue={-1}>
                  <option value={-1} hidden disabled>-- Select Section --</option>
                  {surveySections?.map((survey, index) => {
                    return (
                      <option value={survey} key={`${survey}-option`} selected={surveyName===survey}>
                        {survey}
                      </option>
                    );
                  })}
                </Input>
              </Col>
            </FormGroup>
          </Form>
        </Col>
      )
      }
    </>)
}

import React from 'react';
import { Route } from 'react-router';
import { ToastContextProvider } from 'contexts';
import './sass/custom.scss'
import {
    Layout, Home, RoleAdd, Role, ClinicAdd, Clinic, TestSmartGrid,
    User, CreateUser, Login, ClinicManagement, PreliminaryDataEntry,
    UserDetails, Notifications, NotificationsAdd, DeactivationRequests, 
    CoverKansas, NetworkDataHub, NDHCreatorTool, ClinicTypeOptionList, ClinicTypeOptions
} from 'components';
import { PrivateRoute } from 'components/PrivateRoute';
import { ClinicDataEntry } from 'components/ClinicDataEntry/ClinicDataEntry';
import { LoadingSpinnerComponent } from 'components/LoadingSpinnerComponent';

 const App = () => {

  return (
    <ToastContextProvider>
      <LoadingSpinnerComponent />
      <Layout>
        <PrivateRoute exact={true} path='/' component={Home} />
        <PrivateRoute exact={true} path='/AccountManagement/RoleAdd' component={RoleAdd} />
        <PrivateRoute exact={true} path='/AccountManagement/Role' component={Role} />
        <PrivateRoute exact={true} path='/AccountManagement/ClinicAdd' component={ClinicAdd} />
        <PrivateRoute exact={true} path='/AccountManagement/Clinic' component={Clinic} />
        <PrivateRoute exact={true} path='/AccountManagement/Notifications' component={Notifications} />
        <PrivateRoute exact={true} path='/AccountManagement/NotificationsAdd' component={NotificationsAdd} />
        <PrivateRoute exact={true} path='/NetworkDataHub' component={NetworkDataHub} />
        <PrivateRoute exact={true} path='/PreliminaryData' component={PreliminaryDataEntry} />
        <PrivateRoute exact={true} path='/AccountManagement/Users' component={User} />
        <PrivateRoute exact={true} path='/AccountManagement/User/:id' component={UserDetails}/>
        <PrivateRoute exact={true} path='/AccountManagement/Users/Create' component={CreateUser} />
        <PrivateRoute exact={true} path='/AccountManagement/Users/DeactivationRequests' component={DeactivationRequests} />
        <PrivateRoute exact={true} path='/AccountManagement/Setup' component={ClinicTypeOptionList} />
        <PrivateRoute exact={true} path='/AccountManagement/TypeOptions' component={ClinicTypeOptions} />

        <PrivateRoute path='/ClinicManagement' component={ClinicManagement} />
        <PrivateRoute exact={true} path='/ClinicManagement/ClinicDetails/Users/Create' component={CreateUser}/>
        <PrivateRoute exact={true} path='/ClinicManagement/ClinicDetails/User/:id' component={UserDetails}/>
        <PrivateRoute exact={true} path='/ClinicManagement/ClinicDetails/Users/DeactivationRequests' component={DeactivationRequests} />
        
        <PrivateRoute path='/ClinicDataEntry' component={ClinicDataEntry} />
        <PrivateRoute exact={true} path='/ClinicDataEntry/ClinicDetails/Users/Create' component={CreateUser} />
        <PrivateRoute exact={true} path='/ClinicDataEntry/ClinicDetails/User/:id' component={UserDetails}/>
        <PrivateRoute exact={true} path='/ClinicDataEntry/ClinicDetails/Users/DeactivationRequests' component={DeactivationRequests} />

        <Route exact={true} path='/CoverKansas' component={CoverKansas} />
        <Route path='/Login' component={Login} />
        <PrivateRoute exact={true} path='/NDHCreatorTool' component={NDHCreatorTool} />
      </Layout>
    </ToastContextProvider>
  );
}

export default App;
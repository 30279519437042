import { useLocation, useHistory } from "react-router-dom";
// @ts-ignore
import React, { useState, useEffect } from "react";
import * as ReactDOM from "react-dom";
import "survey-core/defaultV2.min.css";
import { StylesManager, Model, FunctionFactory, Serializer } from "survey-core";
import { Survey } from "survey-react-ui";
import { apiClient, ApiEndpoints } from "../../utilities";
// @ts-ignore
import Inputmask from 'inputmask';
// import 'inputmask/dist/inputmask/phone-codes/phone';
import * as SurveyCore from "survey-core";
// @ts-ignore
import * as widgets from "surveyjs-widgets";
import {
  formatAsUSDollar,
  getSavedDataEntries,
  roundTo,
} from "../../utilities/surveys/functions";

import {
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
} from "reactstrap";
import "./ClinicSurveyComponent.scss";
import { useUserContext } from "contexts";
import { AmendmentBanner } from "./AmendmentBanner";
import { ThreeDots } from  'react-loader-spinner'
import { trackPromise } from "react-promise-tracker";

// Adds 'popupdescription' property to all questions
Serializer.addProperty("question", "popupdescription:text");

// Toggle function to show 'popupdescription'
function showReadMore(element) {
  document.getElementById("questionDescriptionText").innerHTML =
    element.popupdescription;
  var popupEl = document.getElementById("questionDescriptionPopup");
  document.getElementById("questionDescriptionPopupClose").onclick = () => {
    // @ts-ignore
    popupEl.close();
  };
  // @ts-ignore
  popupEl.showModal();
}

FunctionFactory.Instance.register("roundTo", roundTo);
FunctionFactory.Instance.register("formatAsUSDollar", formatAsUSDollar);
FunctionFactory.Instance.register("getSavedDataEntries", getSavedDataEntries,true);

// @ts-ignore
widgets.inputmask(SurveyCore);

export const ClinicSurveyComponent = (props) => {

  const stepRoutes = [
    "PatientData",
    "StaffingAndUtilization",
    "ClinicalMeasures",
    "FinancialMeasures",
    "DataMeasures",
    "Review",
  ];

  const stepRoutesManual = [
    "ManualDataEntry",
    "DataEntryReview",
  ];

  const surveySections = [
    "PatientData",
    "StaffingAndUtilization",
    "ClinicalMeasures",
    "FinancialMeasures",
    "DataMeasures",
    "DataSubmission",
  ];

  const userContext = useUserContext();

  const setClinicNDHStatus = (e) => {
    const sectionStatus = {
      Status: e,
      Section: props.SurveyName,
    };

    window.localStorage.setItem(
      `${props.SurveyName}_ClinicNDHData`,
      JSON.stringify(sectionStatus)
    );
    setStatus(e);
  }
  
  const saveSurveyData = (survey) => {
    const surveyData = survey.data;
    surveyData.pageNo = survey.currentPageNo;
    
    setClinicNDHStatus("InProgress")
  };

  

  const getClinicNDHStatus = () => {
    const section = window.localStorage.getItem(
      `${props.SurveyName}_ClinicNDHData`
    );

    if (section) {
      return JSON.parse(section)?.Status;
    }

    return null;
  };

  const changePage = (survey) => {
    window.history.pushState(
      "object",
      "title",
      stepRoutesManual.includes(props.SurveyName)?"/ClinicDataEntry/" +
        props.SurveyName +
        "#" +
        survey.currentPage.navigationDescription.replace(/\s/g, "")
      :"/ClinicManagement/" +
        props.SurveyName +
        "#" +
        survey.currentPage.navigationDescription.replace(/\s/g, "")
    );    
  };

  // @ts-ignore
  const changePageVisibility = (survey, pageModel) => {
    // @ts-ignore
    window.navSurvey = survey;
  }

  const onChangeReason = (target) =>
  { 
    setAmendmentReason(target.currentTarget.value);
  }

  const getSelectedMenuPageNo = () =>{

    // @ts-ignore
    let survey = window.navSurvey; 

    let path = window.location.href.indexOf("#") > -1
      ? window.location.href.split("#")[1]
      : window.location.href.replace("#", "");

    let page = survey? survey.pages.find((page) => page.navigationDescription.replace(/\s/g, "") === path.replace('#','') && page.isVisible) : undefined;
    
    return page? page.visibleIndex : -1
  }

  const insertData = async (survey, showModal = undefined, sectionStatus = 'InProgress') => {
    
    if(sectionStatus) setClinicNDHStatus(sectionStatus);

    const result = survey.data;
    survey.getAllQuestions().forEach(function(question) {
      if(result[question.name] === undefined) {
        result[question.name] = null; 
      }
      if(question.page?.name !== survey.currentPage?.name)
      {
        delete result[question.name];
      }
      });

    const odssection = {
      ClinicId: userContext?.clinicId,
      DataExtension: JSON.stringify({
        Questions: props.json.pages.find(p=>p.name===survey.currentPage.name),
        Answers: result,
        Version: props.version,
        SubmitionDate: new Date(),
        ClinicId: userContext?.clinicId,
      }),
      OdsSection: survey.currentPage.name ,
    };

    const data = {
      clinicId: userContext?.clinicId,
      Data: JSON.stringify(survey.data),
      Status: sectionStatus,
      Section: props.SurveyName,
      Year: new Date().getFullYear() -1,
      ClinicNDHSections: [odssection],
      AmendmentReason: amendmentReason,
    };

    const url = "ClinicPatientData";
    await apiClient
      .put(url, data, {
        // @ts-ignore
        toastConfig: {
          showSuccess: showModal?true:false,
          successMessage: "Survey saved successfully",
        },
      })
      .then(
        () => {
          var step= stepRoutes.indexOf(props.SurveyName);
          if((step>-1 && step<5) && showModal)
          {
            history.push("/ClinicManagement/"+ stepRoutes[step+1])
          }
          var stepM= stepRoutesManual.indexOf(props.SurveyName);
          if((stepM>-1 && stepM<2) && showModal)
          {
            history.push("/ClinicDataEntry/"+ stepRoutesManual[stepM+1])
          }

        } 
      )
      .catch((error) => console.log(error.response.request._response));
  };

  const location = useLocation();
  // @ts-ignore
  const history = useHistory();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [status, setStatus] = useState(getClinicNDHStatus() || "Started"); //status should be pulled from clinic NDH data which contains overall submission status for clinic
  const [isShown, setIsShown] = useState(status==='Amendment'?true:false);
  const [clinicId, setClinicId] = useState(userContext?.clinicId)
  const [surveyJSData, setSurveyJSData] = useState();
  const [amendmentReason, setAmendmentReason] = useState();

  useEffect(() => {
    const currentYear=new Date().getFullYear() - 1;
    const url = ApiEndpoints.ClinicManagement.ClinicPatientData.GetById.replace("{clinic}", userContext?.clinicId).replace("{section}", props.SurveyName).replace("{year}",currentYear.toString());
    if(!isDataLoaded || clinicId!== userContext?.clinicId )
    {
      apiClient
        .get(url).then(
          (response) => {
            setClinicId(userContext?.clinicId);
            if(response?.data!==null)
            {
              setSurveyJSData(response.data.data)
              setAmendmentReason(response.data.amendmentReason)
              setIsDataLoaded(true);
              if(response.data.status)
               setClinicNDHStatus(response.data.status)
            }
            else
            {
              window.localStorage.removeItem(`${props.SurveyName}_ClinicNDHData`);
              setClinicNDHStatus("Started")
            }
            
          } 
        )
        .catch((error) => {
          window.localStorage.removeItem(`${props.SurveyName}_ClinicNDHData`);
          setClinicNDHStatus("Started")
        });
    }
  },[props.SurveyName, isDataLoaded, userContext?.clinicId, clinicId]);

  const sendNotification = async (message = undefined, url_ =undefined) => {
    var today = new Date();
    var nextWeek = new Date(new Date().getTime()+(7*24*60*60*1000)).toISOString();
    const notificationData ={
      "id": "00000000-0000-0000-0000-000000000000",
      "type": "Actionable",
      "messageBody": message,
      "url": url_,
      "createdBy": userContext?.user.userID,
      "startDate": today.toISOString(),
      "endDate": nextWeek,
      "clinicIds":  userContext?.clinicId,
      "usersIds": "tharkness@communitycareks.org, tkennedy@communitycareks.org",
    };
    
    const url = `${ApiEndpoints.AccountManagement.Notifications.CreateUserNotification}`;
    await trackPromise(apiClient
      .put(url, notificationData,
      )
      .then()
      .catch((error) => console.log(error)));
  };
  // @ts-ignore
  const requestAmendment = (e) => {
    setIsShown(true);
    setClinicNDHStatus("Amendment");
    insertData(survey, false,"Amendment");
  };

  // @ts-ignore
  const handleCancel = (e) => {
    setIsShown(false);
    setClinicNDHStatus("ValidationPass");
    insertData(survey, false,"ValidationPass");
  };

  // @ts-ignore
  const handleSubmit = (e) => {
    setIsShown(false);
    setClinicNDHStatus("AmendmentRequest");
    insertData(survey, false, "AmendmentRequest");
    sendNotification("An amendment for the section " + props.SurveyName + " has been requested. Clinic: " + userContext?.clinicId + " Reason: " + amendmentReason );
  };

  StylesManager.applyTheme("defaultV2");
  var defaultThemeColors = StylesManager.ThemeColors["default"];
  defaultThemeColors["$main-color"] = "#eca820";
  defaultThemeColors["$main-hover-color"] = "#6fe06f";
  defaultThemeColors["$text-color"] = "#4a4a4a";
  defaultThemeColors["$header-color"] = "#7ff07f";
  defaultThemeColors["$header-background-color"] = "#4a4a4a";
  defaultThemeColors["$body-container-background-color"] = "#f8f8f8";
  StylesManager.applyTheme("defaultV2");

  const survey = new Model(props.json);
   // @ts-ignore
  survey.onComplete.add((sender, options) => {});

  survey.setVariable("clinicId", userContext?.clinicId);

  // @ts-ignore
  survey.onAfterRenderQuestion.add(function (survey, options) {
    //Do nothing if a question contains no description to show in a modal popup
    if (!options.question.popupdescription) return;
    //Create a 'More Info' button to invoke a modal popup
    var container = document.createElement("span");
    var question = options.question;
    ReactDOM.render(
      <Button
        color="link"
        size="sm"
        type="button"
        className="dialogBox-btn ms-2"
        onClick={function () {
          showReadMore(question);
        }}
      >
        More Info
      </Button>,
      container
    );
    //Insert the created 'More Info' button into the rendered question's header
    var titleSpan = options.htmlElement.querySelector(
      "span.sv-title-actions__title"
    );
    if (!titleSpan) titleSpan = options.htmlElement;
    titleSpan.appendChild(container);
  });

  survey.onPartialSend.add((survey) => {
    //saveSurveyData(survey);
    insertData(survey,false, "InProgress");
    
  });
  survey.onShowingPreview.add((survey) => {
    //saveSurveyData(survey);
    insertData(survey, true);
  });
  survey.onComplete.add((survey) => {
    //saveSurveyData(survey);
    insertData(survey, true);
  });
  survey.sendResultOnPageNext = true;
  
  var prevData = surveyJSData;
  const pageNo = getSelectedMenuPageNo();
  survey.currentPageNo = pageNo;
  if (prevData) {
    // @ts-ignore
    var data = JSON.parse(prevData);
    survey.data = data;
    if (pageNo > 0) {
      survey.currentPageNo = pageNo;
    }
  }

  if (status === 'Submitted' || status ==='ValidationPass' || status === 'ETLComplete') survey.mode = "display";

  if(status === 'Amendment'){
    survey.showNavigationButtons = false;
  }

  // @ts-ignore
  window.navSurvey = survey;

  return (
    <>
      <dialog id="questionDescriptionPopup" className="dialogBox">
        <div className="dialogBox-header">
          <h4>More Info</h4>
        </div>
        <div className="dialogBox-body">
          <div id="questionDescriptionText"></div>
        </div>
        <div id="questionDescriptionPopupClose" className="dialogBox-footer">
          <button
            type="button"
            className="dialogBox-close btn-primary pill-btn"
          >
            Close
          </button>
        </div>
      </dialog>
      <div id="waitingdiv" className="div-load">
        <ThreeDots 
        height="80" 
        width="80" 
        radius="9"
        color="#ECA820" 
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        visible= {!isDataLoaded}
        /></div>
      
      <AmendmentBanner status={status} reason={amendmentReason}/>
      <Button
        className="btn mt-3"
        color="primary"
        hidden={status !== "ValidationPass"}
        onClick={requestAmendment}
        disabled={isShown}
      >
        Request Amendment
      </Button>
      <Survey
        model={survey}
        onCurrentPageChanged={changePage}
        onPageVisibleChanged={changePageVisibility}
        className="data-entry-survey"
      />
      {isShown && (
        <FormGroup row className="mb-4">
          <Col md={4}>
            <Label
              for="amendmentReason"
              md={8}
              className="label"
              hidden={status !== "Completed" && status !== "Amendment"}
            >
              Reason for Amendment:
            </Label>
          </Col>
          <Col md={6}>
            <Input
              id="amendmentReason"
              type="textarea"
              rows="4"
              height="200px"
              onChange={onChangeReason}
            />
            <FormFeedback>Reason Required.</FormFeedback>
          </Col>
          <Row>
            <Col>
              <Button className="btn mt-3" color="secondary" onClick={handleCancel}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                className="btn mt-3"
                color="primary"
                onClick={handleSubmit}
              >
                Submit Amendment
              </Button>
            </Col>
          </Row>
        </FormGroup>
      )}
    </>
  );
};

const nonNegativeWholeNumberValidator = [
  {
    type: "numeric",
    text: "Value cannot be less than 0",
    minValue: 0,
  },
  {
    type: "regex",
    text: "Value must be a whole number",
    regex: "^\\d+$"
  }
];

export const coverKansasJSON = {
  title: "Navigator Assistance Report",
  showProgressBar: "top",
  showQuestionNumbers: "off",
  focusFirstQuestionAutomatic: false,
  checkErrorsMode: "onValueChanged",
  width: "80%",
  minWidth: "400px",
  maxWidth: "800px",
  navigateToUrl: "https://communitycareks.sharefile.com/share/upload/rd530af2c70774c9fa22382b32e422cba",
  completedHtml: "<div class='row d-flex justify-content-around'><div class='col-lg-6 m-5 p-5'><h3><b>Thank you for completing the Navigation Assistance Report. You will be redirected to url: <a href='https://communitycareks.sharefile.com/share/upload/rd530af2c70774c9fa22382b32e422cba'>https://communitycareks.sharefile.com/share/upload/rd530af2c70774c9fa22382b32e422cba</a></b></h3></div></div>",
  pages: [{
    name: "CoverKansas",
    elements: [
      {
        type: "panel",
        name: "healthInsuranceInquiries",
        title: "",
        elements: [
          {
            type: "html",
            html: "<div class='pt-4'><strong>1. General Inquiries About Health Insurance Options</strong></div>"
          },
          {
            type: "text",
            name: "healthInsuranceInquiriesCount",
            title: "Number of consumers who asked about healthcare options, including but not limited to - coverage available through Healthcare.gov, Medicaid/CHIP, or any other applicable sources of coverage available to the consumer.",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          },
        ]
      },
      {
        type: "panel",
        name: "understandingBasicConcepts",
        title: "",
        elements: [
          {
            type: "html",
            html: "<div class='pt-4'><strong>2. Understanding Basic Concepts and Rights Related to Health Coverage and How To Use it</strong></div>"
          },
          {
            type: "html",
            html: "2A. Health Insurance Literacy"
          },
          {
            type: "text",
            name: "healthInsuranceLiteracyCount",
            title: "List the total number of consumers assisted with health insurance literacy. Literacy means competency or knowledge about a certain topic. Example: A consumer asks a Navigator how to use his or her health insurance card, or asks about the difference between premium and deductible.",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          },
          {
            type: "html",
            html: "2B. Locating Providers"
          },
          {
            type: "text",
            name: "locatingProvidersCount",
            title: "Number of consumers you helped with locating providers approved by their plan.",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          },
          {
            type: "html",
            html: "2C. Billing and Payment Questions/Assistance"
          },
          {
            type: "text",
            name: "billingPaymentAssistanceCount",
            title: "Number of consumers who you helped with billing and payment issues.",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          },
          {
            type: "html",
            html: "2D.Evaluating Healthcare Options via Consumer Plan"
          },
          {
            type: "text",
            name: "healthCareOptionsAssistanceCount",
            title: "Number of consumers assisted with evaluating their healthcare options using tools and information available through the consumer's health plan. Only count interactions here where evaluation of healthcare options is specific to information made by accessing the consumer's health plan information.",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          },
          {
            type: "html",
            html: "2E. Accessing Preventative Health Services"
          },
          {
            type: "text",
            name: "preventableServicesAssitanceCount",
            title: "Number of consumers assisted with accessing preventable health services. Only count interactions here where assistance in accessing preventable health services is based off of coverage information specific to the consumer's health plan.",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          }
        ]
      },
      {
        type: "panel",
        name: "marketplaceApplicationAssementAndPrep",
        title: "",
        elements: [
          {
            type: "html",
            html: "<div class='pt-4'><strong>3. Marketplace Application Assessment and Preparation</strong></div>"
          },
          {
            type: "html",
            html: "3A.Healthcare.gov Account Creation/Troubleshooting"
          },
          {
            type: "text",
            name: "accountsCreatedOrAssistedCount",
            title: "Total number of Healthcare.gov accounts you helped to create and/or Healthcare.gov account creation troubleshooting assistance provided.",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          },
          {
            type: "html",
            html: "3B. Marketplace Eligibility and Results Review"
          },
          {
            type: "text",
            name: "eligibilityAssessmentsCount",
            title: "Count here the number of Marketplace Eligibility Assessments and Results Review provided, per consumer interaction. Count this on a per-family basis when an eligibility assessment and results review includes multiple individuals, per coverage type.",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          },
          {
            type: "html",
            html: "3C. Marketplace Plan Compare"
          },
          {
            type: "text",
            name: "consumersAssistedCount",
            title: "Count here the number of consumers assisted with Marketplace plan compare and review.",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          },
        ]
      },
      {
        type: "panel",
        name: "marketplaceAppEnrollment",
        title: "",
        elements: [
          {
            type: "html",
            html: "<div class='pt-4'><strong>4. Marketplace Application Submission and Enrollment</strong></div>"
          },
          {
            type: "html",
            html: "4A. Electronic QHP Enrollment Using Healthcare.gov"
          },
          {
            type: "text",
            name: "qhpElectronicEnrollAssistCount",
            title: "Number of consumers you helped to enroll in a QHP, electronically, using the Federally-facilitated Marketplace Website.",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          },
          {
            type: "html",
            html: "4B. Telephone QHP Enrollment w/Call Center"
          },
          {
            type: "text",
            name: "qhpPhoneEnrollAssistCount",
            title: "Count here the number of consumers you helped enroll in a QHP, telephonically, using the Federally-facilitated Marketplace call center.",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          },
          {
            type: "html",
            html: "4C. Written QHP Enrollment w/Paper Application"
          },
          {
            type: "text",
            name: "qhpWrittenEnrollAssistCount",
            title: "Count here the number of consumers you helped enroll in a QHP, using a Federally-facilitated Marketplace paper application.",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          }
        ]
      },
      {
        type: "panel",
        name: "medicaidOrCHIPAppsOrReferrals",
        title: "",
        elements: [
          {
            type: "html",
            html: "<div class='pt-4'><strong>5. Medicaid/CHIP Applications or Referrals</strong></div>"
          },
          {
            type: "html",
            html: "Count all of the consumers in this category where you: <br> - Assist a consumer(s) with completing a Marketplace application where they're deemed eligible for Medicaid/CHIP. <br>- Directly enroll a consumer in Medicaid/CHIP (or assessing eligibility) through a state portal.<br> - Refer a consumer to the state Medicaid/CHIP office for additional assistance, or to complete the enrollment process."
          },
          {
            type: "text",
            name: "medicaidOrCHIPconsumerAssistedCount",
            title: " ",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          }
        ]
      },
      {
        type: "panel",
        name: "otherEnrollmentIssues",
        title: "",
        elements: [
          {
            type: "html",
            html: "<div class='pt-4'><strong>6. Complex Cases and Other Marketplace Enrollment Issues</strong></div>",
          },
          {
            type: "html",
            html: "6A. Complex Case and Other Marketplace Issues"
          },
          {
            type: "text",
            name: "helpCenterReferralsCount",
            title: "Count the number of referrals to the Complex Case Help Center using the Complex Case Submission Web Form located here: https://mats.secure.force.com/complexcase/",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          },
          {
            type: "html",
            html: "6B. Data Matching Issues/Periodic Matching Issues"
          },
          {
            type: "text",
            name: "dataMatchingHelpCount",
            title: "Count the number of times you helped consumers with data matching issues. Healthcare.gov defines data matching issues as: A difference between information a consumer put on their Marketplace health insurance application and information Healthcare.gov has from other trusted data sources.",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          },
          {
            type: "html",
            html: "6C. SEP Eligibility Troubleshooting Assistance"
          },
          {
            type: "text",
            name: "sepEligibilityAssistanceCount",
            title: "Count the number of times you assisted consumers with SEP eligibility troubleshooting. SEP= Special Enrollment Period",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          },
          {
            type: "html",
            html: "6D. Employer Sponsored Coverage Issue Assistance"
          },
          {
            type: "text",
            name: "employerSponsoredAssistanceCount",
            title: "Count the number of times you assisted consumers with employer sponsored coverage.",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          },
          {
            type: "html",
            html: "6E. APTC/CSR Assistance:"
          },
          {
            type: "text",
            name: "aptcOrCsrAssistanceCount",
            title: "APTC= Advanced Premium Tax Credit CSR= Cost Sharing Reductions",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          },
          {
            type: "html",
            html: "6F. Other"
          },
          {
            type: "text",
            name: "complexCasesOther",
            inputType: "number",
            title: " ",
          },
        ]
      },
      {
        type: "panel",
        name: "generalQuestionsAndReferrals",
        title: "",
        elements: [
          {
            type: "html",
            html: "<div class='pt-4'><strong>7. Answering Questions About and/or Making Referrals To:</strong></div>"
          },
          {
            type: "html",
            html: "7A. Agent/Broker"
          },
          {
            type: "text",
            name: "agentBrokerCount",
            title: "Count of consumers who asked about an agent/broker. If a consumer asks you for an agent/broker, then you cannot refer a consumer to a specific agent/broker, but can provide consumers with a list of agents/brokers in the area.",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          },
          {
            type: "html",
            html: "7B. Issuers"
          },
          {
            type: "text",
            name: "issuersCount",
            title: "This category is intended to capture the number of instances where an enrolled consumer needs to be referred to their issuer for help resolving an issue/question that you cannot answer. If the consumer is asking on behalf of other household members, please count all the family members who need assistance",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          },
          {
            type: "html",
            html: "7C. Issuers"
          },
          {
            type: "text",
            name: "medicareCount",
            title: "Count the number of consumers that you refer to Medicare or a local State Health Insurance Program that can help with Medicare issues. In general, it is NOT allowed for a consumer who is already enrolled in Medicare to enroll in a QHP. However, there are unique and complicated circumstances where if a consumer has only Medicare Part B, that isn't considered to meet the minimum essential coverage requirement. The best and recommended course of action is to refer them to review https://www.healthcare.gov/medicare/ or refer them to someone who deals with Medicare at https://www.medicare.gov/",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          },
          {
            type: "html",
            html: "7D. Other Assistance/Health Insurance Program",
            title: "Please count the number of consumers you assisted with other health insurance programs. This may include, but is not limited to, referrals to TRICARE, the Veterans Administration, or state healthcare assistance programs.",
          },
          {
            type: "text",
            titleLocation: "left",
            name: "tricareAssistanceCount",
            title: "Tricare",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          },
          {
            type: "text",
            titleLocation: "left",
            name: "vaAssistanceCount",
            title: "Veterans Administration (VA)",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          },
          {
            type: "text",
            titleLocation: "left",
            name: "otherCount",
            title: "Other",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          },
          {
            type: "html",
            html: "7E. State Departments of Insurance"
          },
          {
            type: "text",
            name: "stateInsuranceDeptReferralCount",
            title: "Include here the number referrals to state departments of insurance for help answering consumer questions or resolving issues handled by the Department of Insurance. (Do not put letters, only numbers) For example, the consumer feels that there is a fraudulent website or wants to report concerns about a QHP.",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          },
        ]
      },
      {
        type: "panel",
        name: "formsExemptionsAndAppeals",
        title: "",
        elements: [
          {
            type: "html",
            html: "<div class='pt-4'><strong>8. Forms, Exemptions and Appeals</strong></div>"
          },
          {
            type: "html",
            html: "8A. Marketplace Tax Forms (1095-A)"
          },
          {
            type: "text",
            name: "taxFormAssistanceCount",
            title: "It is important to remember that Navigators cannot give tax advice. If a consumer requests tax assistance, you should refer them to a local Volunteer Income Tax Assistance (VITA) program, Tax Counseling for the Elderly (TCE) program, or a reputable tax advisor. You can help with informing the consumer about the need to file taxes in order to reconcile their 2016 APTCs, requesting a revised 1095-A, accessing their 1095-A, or explaining tax related information they receive from the Marketplace; all of these would be counted under this section. All of these items fall under interaction with consumers. Count the number of consumers you assisted in this category.",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          },
          {
            type: "html",
            html: "8B. Filing Marketplace Exemptions"
          },
          {
            type: "text",
            name: "filingExemptionAssistanceCount",
            title: "Count the number of consumers assisted. You can only assist consumers with Marketplace exemptions. Exemptions available through the IRS must be claimed through a consumer's taxes.",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          },
          {
            type: "html",
            html: "8C. Marketplace/Insurance Coverage Appeals"
          },
          {
            type: "text",
            name: "insuranceAppealImpactedConsumersCount",
            title: "Count the number of consumers impacted on each appeal form. An appeal can be counted as submitted in this category so long as everything but the mailing of the form is done with the assistance of a Navigator.",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          },
          {
            type: "html",
            html: "<b>Description of Other Consumer Assistance:</b>"
          },
          {
            type: "text",
            name: "otherConsumerAssistanceCount",
            title: "If applicable, please provide a description of any other type of consumer assistance not listed on this form.",
            inputType: "number",
            validators: nonNegativeWholeNumberValidator
          },
        ]
      }
    ]
  }]
}
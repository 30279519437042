import React, { useEffect, useState } from 'react';
import { Row, Col, Button, ButtonGroup, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { SmartGrid } from 'components/smart-grid';
import { ColDisplayConfig } from 'models';
import { ApiEndpoints, ApiRoutes, ApplicationPermissions, apiClient } from 'utilities';
import { trackPromise } from 'react-promise-tracker';
import { useUserContext } from 'contexts';
import { Link, useHistory, useLocation } from 'react-router-dom';

export const ClinicTypeOptions = ({data}) => 
{
    const [formData, setFormData] = useState({
        id: 0,
        Name: '',
        Description: '',
        GroupName: 'Designation',
        IsActive: true,
    });
    const location = useLocation();
    const history = useHistory();
    
    const updateClinic = location.state?.clinicUpdate;
    const [isEditMode, setIsEditMode] =useState(updateClinic!==undefined)

    const groups =["Designation",    "Membership Participation",    "Special Service Area",    "Membership Type"]
    

    useEffect(() => {
      if(updateClinic){
        setFormData({
          id: updateClinic.id,
          Name: updateClinic.name,
          Description: updateClinic.description,
          GroupName: updateClinic.groupName,
          IsActive: updateClinic.isActive,
      })
      }
    }, [updateClinic])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleCancel = () =>{
      history.push('/AccountManagement/Setup');
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        insertData();
    };
    const insertData = async () => {
      
      const url = `${ApiEndpoints.AccountManagement.ClinicTypeOptions.Create}`;
      await trackPromise(apiClient
        .put(url, formData, {
          // @ts-ignore
          toastConfig: {
            showSuccess: true,
            successMessage: `Clinic Options ${
              isEditMode ? "updated" : "created"
            } successfully!`,
          },
        })
        .then(() => history.push('/AccountManagement/Setup'))
        .catch((error) => console.log(error)));
    };
    
    return (
        <>
        <h3 className='primary title'>{updateClinic ? "Update" : "Add" } Clinic Options</h3>
        <Form onSubmit={handleSubmit}>
        <FormGroup row >
            <Label for="GroupName" sm={3} className='label'>Clinic Type Group:</Label>
            <Col md={4}>
            <Input
            type="select"
            name="GroupName"
            id="GroupName"
            disabled={isEditMode}
            value={formData.GroupName}
            onChange={handleInputChange}
            >
            {groups.map((group) => {
            return( <option key={group} value={group}>{group}</option>)
            })}
            </Input>
            </Col>
            <Col sm={4}>
            <Input
                type="checkbox"
                name="IsActive"
                checked={formData.IsActive}
                onChange={(e) =>
                setFormData((prevState) => ({
                    ...prevState,
                    IsActive: e.target.checked,
                }))
                }
            />{' '} Is Active
            
            </Col>
        </FormGroup>
        <FormGroup row className='mb-4'>
            <Label for="Name" sm={3} className='label'>Option Name:</Label>
            <Col md={8}>
            <Input
            type="text"
            name="Name"
            id="Name"
            disabled={isEditMode}
            value={formData.Name}
            onChange={handleInputChange}
            />
            </Col>
        </FormGroup>
        <FormGroup row className='mb-4'>
            <Label for="Description" sm={3} className='label'>Description:</Label>
            <Col md={8}>
            <Input
            type="text"
            name="Description"
            id="Description"
            value={formData.Description}
            onChange={handleInputChange}
            />
            </Col>
        </FormGroup>

        <ButtonGroup>
          <Row className='mt-3 d-flex justify-content-end'>
          <Col sm={{size: 'auto'}}>
            <Button className="btn mt-3 px-4" color="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </Col>
          <Col sm={{size: 'auto'}}>
            <Button className="btn mt-3 px-4" color="primary" onClick={handleSubmit} type="submit">
             {updateClinic ? "Update" : "Save" }
            </Button>
          </Col>
          </Row>
        </ButtonGroup>
           
        </Form>
        </>
    );
};

export const ClinicTypeOptionList = () =>{

    const [refreshGrid, setRefreshGrid] = useState(false);
    const userContext = useUserContext();
    const user = userContext.user;
    const canView = userContext?.hasPermission(ApplicationPermissions.AccountManagement.ViewClinics);
    const canCreate = userContext?.hasPermission(ApplicationPermissions.AccountManagement.CreateClinics);
    const [clinicTypeOptions, setClinicTypeOptions]= useState([]);
    
    useEffect(() => {
        trackPromise(apiClient.get(`${ApiEndpoints.AccountManagement.ClinicTypeOptions.GetAllAsync}`)
        .then((response) => {
            setClinicTypeOptions(response.data);
        }).catch(resp => {
            setClinicTypeOptions([]);
        }));
      }, [refreshGrid]);
    
     const actionsCell = (row) => {
          return (
          <ButtonGroup className="role-actions-button-group">
              <Link to={{
                pathname:"/AccountManagement/TypeOptions",
                state : { clinicUpdate: row }
              }}>
              <Button>Update</Button>
            </Link>
        </ButtonGroup>
       )}
    
       const displayStatus = (row) => {
        return row.isActive? 'Yes' : 'No';
      }
    return (
        <>
        <h3 className='primary title'>Clinics Options Setup</h3>
        {canCreate && (
            <Row>
              <Col className ='col-sm-10'>
                <form>
                  <Link to="/AccountManagement/TypeOptions">
                    <Button
                      color="primary"
                      className="mt-3 pill-btn"
                      outline
                    >
                      Add Clinic Options
                    </Button>
                  </Link>
                </form>
              </Col>
            </Row>)
          }
          {canView && (
          <Row>
          <SmartGrid
              data={clinicTypeOptions}
              url={`${process.env.REACT_APP_API_BASE_URL}/${ApiEndpoints.AccountManagement.ClinicTypeOptions.GetAllAsync}`}
              columnConfig={[
                    //new ColDisplayConfig("name", "Name", false, false),
                    new ColDisplayConfig("description", "Description", false, false),
                    new ColDisplayConfig("groupName", "GroupName", false, false),
                    new ColDisplayConfig("isActive", "IsActive", false, false,displayStatus),
                    new ColDisplayConfig("", "", false, false, actionsCell)
                ]}
              refreshGrid={refreshGrid}
            />
          </Row>)
        }
        </>     
      );
}
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "./PreliminaryDataEntry.scss";
import { useUserContext } from "contexts";
import { ApiEndpoints, ApplicationPermissions, apiClient } from "utilities";
import modal from "bootstrap/js/dist/modal";
import { ReadMore } from "components/ReadMore";
import { trackPromise } from "react-promise-tracker";

export const PreliminaryDataEntry = () => {
  const userContext = useUserContext();
  const user = userContext.user.userID;
  const clinicId = userContext.clinicId;
  const currentYear = new Date().getFullYear() -1;
  const canSubmit = userContext?.hasPermission(ApplicationPermissions.ClinicManagement.SubmitDataEntry);
  const [preliminaryData, setPreliminaryData] = useState({
    totalVisits: null,
    totalPatients: null
  });

  const handleChange = (event) => {
    setPreliminaryData({ ...preliminaryData, [event.target.name]: event.target.value });
  }

  // take the prelim data obj currently in state and submit to the backend api
  const handleSubmit = (event) => {
    event.preventDefault();

    const submitDate = new Date();
    const url = ApiEndpoints.ClinicManagement.PreliminaryData.Submit;
    trackPromise(apiClient.put(url, {
      clinicId,
      year: submitDate.getFullYear() -1,
      totalVisits: preliminaryData.totalVisits ?? 0,
      totalPatients: preliminaryData.totalPatients ?? 0,
      submittedByWhom: user,
      submitted: submitDate
    },
    {
      // @ts-ignore
      toastConfig: {
        showSuccess: true,
        successMessage: "Preliminary Data submitted successfully!"
      },
    }
    ));
  };

  const getPrelimData = () => {
    const url = ApiEndpoints.ClinicManagement.PreliminaryData.GetSubmission.replace("{year}", currentYear.toString()).replace("clinicId", clinicId);
    trackPromise(apiClient.get(url).then((response) => {
        setPreliminaryData({
        totalPatients: response.data.totalPatients,
        totalVisits: response.data.totalVisits
      })
    }))
  };

  useEffect(() => {
    getPrelimData();
  }, []);

  return (
    <>

        <Form className="col-sm-8 mx-auto" onSubmit={handleSubmit}>
        <h3 className='primary title'>Preliminary Data</h3>
          <FormGroup row>
            <Label 
              for="totalPatients"
            >
              <b>Total Unduplicated Patients</b>
              <ReadMore linkText="More Info" titleText="Total Patients">
                <p>
                  Patients are people who have at least one countable visit during the reporting year. The term “patient” applies to everyone who receives clinic or virtual visits, not just those who receive medical or dental services.
                </p>
              </ReadMore>
            </Label>

            <Col 
            >
            <Input
              id="totalPatients"
              name="totalPatients"
              type="number"
              value={preliminaryData.totalPatients}
              onChange={handleChange}
              min="0"
              disabled = {canSubmit === false}
              required
            />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label 
              for="totalVisits"
            >
              <b>Total Visits</b>
              <ReadMore linkText="More Info" titleText="Total Visits">
                <p>
                  Visits are encounters between a patient and a licensed or credentialed provider who exercises independent professional judgment in providing services that are:
                  <ul>
                    <li>documented,</li>
                    <li>individual, and</li>
                    <li>in-person or virtual.</li>
                  </ul>
                  Please count only visits that meet all these criteria.
                </p>
              </ReadMore>
            </Label>
            <Col>
            <Input
              id="totalVisits"
              name="totalVisits"
              type="number"
              value={preliminaryData.totalVisits}
              onChange={handleChange}
              min="0"
              disabled = {canSubmit === false}
              required
            />
            </Col>
          </FormGroup>
          <FormGroup row className="mt-4">
            <div>
              <Button 
                type="submit" 
                color="primary"
                className="float-end mt-3 pill-btn"
                outline
                disabled = {canSubmit === false}
              >
                Submit
              </Button>
            </div>
          </FormGroup>
        </Form>
    </>
  );
};

import { ApiEndpoints } from "utilities/StaticRoutes";
import { apiClient } from "utilities/api";
import { useUserContext } from "contexts";

function roundTo(params) {
  const value = params[0];
  const precision = params[1];
  return parseFloat(value).toFixed(precision);
}

function formatAsUSDollar([numString]) {
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  if (numString == null) {
    return USDollar.format(0.0);
  }
  return USDollar.format(numString);
}

// Function to access previously saved survey data
async function getSavedDataEntries([clinicId, surveyName, ...entries]) {
  const currentYear = new Date().getFullYear() -1;
  let data = null;
  let sum = 0;
  let survey = null;
  if (!clinicId) {
    this.returnResult(0);
    return;
  }
  const url = ApiEndpoints.ClinicManagement.ClinicPatientData.GetById.replace("{clinic}", clinicId).replace("{section}", surveyName).replace("{year}", currentYear.toString());
  await apiClient
    .get(url).then(
      (response) => {
        survey = response.data.data
        return survey;
      }
    )
    .then((survey) => {
      if (!survey) return [];
      const surveyJSON = JSON.parse(survey);

      let data = [];
      for (let entry of entries) {
        data = [...data, surveyJSON[entry]];
        sum = sum + surveyJSON[entry];
      }
      this.returnResult(sum);
    })
    .catch((error) => {
      console.log('Error' + error);
    });

}

async function apiGetCurrentSurveyTemplate(surveyName, dataJSON) {
  const data = await apiClient.get(`SurveyTemplate/${surveyName}`).then((response)=>
     {
      if(response?.data !==''){
        return {
          version: response.data.version, 
          template: JSON.parse(response.data.template)
        };
      }
      else 
      {
        return{
          version: 0, 
          template: dataJSON
        };
      }
    }).catch( ()=> {return {
      version: 0, 
      template: dataJSON
    };})
  return data;
}

export { roundTo, formatAsUSDollar, getSavedDataEntries, apiGetCurrentSurveyTemplate };
export const ApiRoutes = {
  AccountManagement: {
    Roles: {
      View: "/AccountManagement/Role",
      Create: "/AccountManagement/RoleAdd"

    },
    Permissions: "/Role/permissions",
    Clinics: "/Clinic",
    ClinicDetails: "/Clinic/:id",
    Users: {
      Create: "/AccountManagement/User/Create",
      View: "/AccountManagement/Users",
    },
    Notifications: {
      View: "/AccountManagement/Notifications",
      Create: "/AccountManagement/NotificationsAdd",
    },
  },
  PreliminaryData: "/PreliminaryData",
  ClinicDetails: "/ClinicDetails",
  ClinicDataEntry: "/ClinicManagement/ClinicDetails#ClinicInformation",
  ClinicUploadManualEntry: "/ClinicDataEntry/ClinicDetails#ClinicInformationNDH",
  NetworkDataHub: "/NetworkDataHub",
  CoverKansas: "/CoverKansas"
};

export const ApiEndpoints = {
  AccountManagement: {
    Users: {
      GetAllAsync: "User/GetAllAsync",
      GetById: "User/GetById/{id}",
      Create: "User/Create",
    },
    Notifications: {
      GetAllCreatedBy: "Notifications/GetAllCreatedBy",
      GetByUserId: "Notifications/GetByUserId",
      CreateClinicNotification: "Notifications/CreateClinicNotification",
      CreateUserNotification: "Notifications/CreateUserNotification",
      Delete: "Notifications/Delete",
      Dismiss: "Notifications/Dismiss",
    },
    ClinicTypeOptions:{
      GetAllAsync: "ClinicTypeOptions/GetAllAsync",
      GetById: "ClinicTypeOptions/GetById/{id}",
      Create: "ClinicTypeOptions/Create",
    },
  },
  NetworkDataHub: {
    Announcements: {
      View: "/Announcements/GetAll",
      Create: "/Announcements/Create",
      Delete: "/Announcements/Delete",
      Reorder: "Announcements/Reorder",
    },
    Resources: {
      View: "/Resources/GetAll",
      Create: "/Resources/Create",
      Delete: "/Resources/Delete",
      Reorder: "Resources/Reorder",
    },
    Contacts: {
      View: "/Contacts/GetAll",
      Create: "/Contacts/Create",
      Delete: "/Contacts/Delete",
      Reorder: "Contacts/Reorder",
    },
    Settings: {
      View: "/NDHSettings/GetAll",
      Create: "/NDHSettings/Create",
    },
  },
  ClinicManagement: {
    ClinicPatientData: {
      DesignationAmendment: 'ClinicPatientData/DesignationAmendment',
      DataSubmission: 'ClinicPatientData/DataSubmission',
      Base: 'ClinicPatientData',
      GetById: 'ClinicPatientData/GetByIdAsync?clinicId={clinic}&section={section}&year={year}',
      UdsFileUpload: '/DataUploads/UdsFileUpload'
    },
    PreliminaryData: {
      GetSubmission: 'ClinicPatientData/PreliminaryDataSubmission/{year}/{clinicId}',
      Submit: 'ClinicPatientData/PreliminaryDataSubmission'
    }
  },
  CoverKansas: {
    GetNavigator: '/User/Navigator/GetById/{id}',
    Settings: {
      View: "/CoverKansasSettings/GetAll",
      Create: "/CoverKansasSettings/Create",
    }
  }
};

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { useStates } from "react-us-states";
import {
  Row,
  Col,
  Container,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Button,
  Form,
} from "reactstrap";
import { apiClient, ApiRoutes, ApplicationPermissions } from "utilities";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { useUserContext } from 'contexts';
import { DesignationChangeRequest } from "components/AccountManagement/Clinic/DesignationChangeRequest";
import { trackPromise } from "react-promise-tracker";

const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    return result;
  }, {}); // empty object is the initial value for result object
};

export const ClinicInformation = ({ clinicData = {} }) => {
  const {
    formState: { errors },
    register,
    setValue,
    watch,
    handleSubmit,
  } = useForm();

  const unitedStatesStates = useStates();

  const location = useLocation();
  const history = useHistory();

  const [showClinicInformation, setShowClinicInformation] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [clinic, setClinic] = useState({});
  const [userInfo, setUserInfo] = useState({ designations: [] });
  const [dentalOnlyChecked, setDentalOnlyChecked] = useState(false);
  const [showDesignationChange, setShowDesignationChange] = useState(false);
  const [isClinicDataSubmitted, setIsClinicDataSubmitted] = useState(false);

  const userContext = useUserContext();
  const canView = userContext?.hasPermission(ApplicationPermissions.ClinicManagement.ViewClinicDetails);
  const canUpdate = userContext?.hasPermission(ApplicationPermissions.ClinicManagement.UpdateClinicDetails);

  const { ref: nameRef, ...nameRegisterProps } = register("name", {
    required: true,
  });
  const { ref: businessAddressLine1Ref, ...businessAddressLine1RegisterProps } =
    register("businessAddressLine1", { required: false });
  const { ref: businessAddressLine2Ref, ...businessAddressLine2RegisterProps } =
    register("businessAddressLine2", { required: false });
  const { ref: cityRef, ...cityRegisterProps } = register("city", {
    required: false,
  });
  const { ref: stateRef, ...stateRegisterProps } = register("state", {
    required: false,
  });
  const { ref: zipRef, ...zipRegisterProps } = register("zip", {
    required: false,
  });
  const { ref: countyRef, ...countyRegisterProps } = register("county", {
    required: false,
  });

  const { ref: mailingAddressLine1Ref, ...mailingAddressLine1RegisterProps } =
    register("mailingAddressLine1", { required: false });
  const { ref: mailingAddressLine2Ref, ...mailingAddressLine2RegisterProps } =
    register("mailingAddressLine2", { required: false });
  const { ref: mailingCityRef, ...mailingCityRegisterProps } = register(
    "mailingCity",
    { required: false }
  );
  const { ref: mailingStateRef, ...mailingStateRegisterProps } = register(
    "mailingState",
    { required: false }
  );
  const { ref: mailingZipRef, ...mailingZipRegisterProps } = register(
    "mailingZip",
    { required: false }
  );

  const { ref: phoneRef, ...phoneRegisterProps } = register("phone", {
    required: false,
  });
  const { ref: faxRef, ...faxRegisterProps } = register("fax", {
    required: false,
  });
  const { ref: emailRef, ...emailRegisterProps } = register("email", {
    required: false,
  });
  const { ref: websiteRef, ...websiteRegisterProps } = register("website", {
    required: false,
  });
  const { ref: notesRef, ...notesRegisterProps } = register("notes", {
    required: false,
  });

  const getSubmissionStatus = () => {
    const data = window.localStorage.getItem("DataSubmission_ClinicNDHData");
    if (data) {
      return JSON.parse(data).Status;
    }

    return null;
  };

  const handleIsSameMailingAddress = (val) => {
    setValue("isMailingAddressDifferent", val);
  };

  const handleOnSubmit = async (data) => {
    data.contacts = clinic["contacts"] ? clinic["contacts"] : [];
    data.designations = clinic["designations"] ? clinic["designations"] : [];
    data.membershipParticipations = clinic["membershipParticipations"]
      ? clinic["membershipParticipations"]
      : [];
    data.specialServices = clinic["specialServices"]
      ? clinic["specialServices"]
      : [];

    data.designations.forEach(
      (item, index, arr) => (arr[index].designation = item.designation)
    );
    data.membershipParticipations.forEach(
      (item, index, arr) => (arr[index] = item.id)
    );
    data.specialServices.forEach((item, index, arr) => (arr[index] = item.id));

    data.membershipType = data.membershipType.name; 

    const url = ApiRoutes.AccountManagement.Clinics;
    await trackPromise(apiClient
      .put(url, data, {
        // @ts-ignore
        toastConfig: {
          showSuccess: true,
          successMessage: "Clinic updated successfully!",
        },
      })
      .then((resp) => {
        history.push(location.pathname.split("#")[0] + "#ActiveAccounts");
      })
      .catch((error) => console.log(error)));

    return false;
  };

  const handleCheckOption = (e) => {
    if (e.target.id === "DentalOnly_DSG")
      setDentalOnlyChecked(e.target.checked);

    // Destructuring
    const { id, checked } = e.target;
    const { designations } = userInfo;

    const { key } = permissions["Designation"].find((e) => e.name === id);

    // Case 1 : The user checks the box
    if (checked) {
      setValue(`designations.${key}.selected`, true);
      setValue(`designations.${key}.designation`, key);

      setUserInfo({
        designations: [...designations, id],
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setValue(`designations.${key}.selected`, false);
      setUserInfo({
        designations: designations.filter((e) => e !== id),
      });
    }
  };

  const handleDesignationRequestChange = () => {
    setShowDesignationChange(true);
  };

  const handleCancelDesignationChange = () => {
    setShowDesignationChange(false);
  };

  const handleSubmitDesignationChange = () => {
    const designations = watch("designations");
    let data = {
      clinicId: clinic["id"],
      designations: designations
        ? designations.filter((e) => e && e.selected)
        : [],
    };

    data.designations.forEach(
      (item, index, arr) =>
        (arr[index].designationDate = item.designationDate
          ? item.designationDate.split("T")[0] + "T00:00:00Z"
          : null)
    );

    trackPromise(apiClient
      .post("ClinicPatientData/DesignationAmendment", data, {
        // @ts-ignore
        toastConfig: {
          showSuccess: true,
          successMessage: "Designation Amendment created successfully!",
        },
      })
      .then( () => history.go(0))
      .catch((error) => console.log(error)));
  };

  useEffect(() => {
    const clinicId =
      clinicData && clinicData["id"] !== undefined ? clinicData["id"] : null; // "fcbc90a4-0cd5-488f-8d99-b94ca1e97d56";

    if (clinicId) {
      const url = `${ApiRoutes.AccountManagement.ClinicDetails.replace(
        ":id",
        clinicId
      )}`;
      trackPromise(apiClient
        .get(url)
        .then((response) => {
          setClinic(response.data);
        })
        .catch((resp) => {}));

      const optionsUrl = `${ApiRoutes.AccountManagement.Clinics}/options`;
      trackPromise(apiClient
        .get(optionsUrl)
        .then((response) => {
          setPermissions(groupBy(response.data, "groupName"));
        })
        .catch((resp) => {
          setPermissions({});
        }));
    }
  }, [clinicData, setClinic]);

  useEffect(() => {
    const path = location.pathname + location.hash;
    if (path.indexOf("ClinicInformation") > 0) {
      setShowClinicInformation(true);
    } else {
      setShowClinicInformation(false);
    }

    if (location.state) setClinic(location.state["clinicUpdate"]);
  }, [location]);

  useEffect(() => {
    if (dentalOnlyChecked) {
      permissions["Designation"]?.forEach((option) => {
        if (option.id === 1)
          setUserInfo({
            designations: [option.name],
          });
        else setValue(`designations.${option.id}.selected`, false);
      });
    }
  }, [dentalOnlyChecked, permissions, setValue, setUserInfo]);

  useEffect(() => {
    const submissionStatus = getSubmissionStatus();
    if (submissionStatus === "Completed") {
      setIsClinicDataSubmitted(true);
    }
  }, [isClinicDataSubmitted]);

  useEffect(() => {
    if (clinic) {
      setValue("id", clinic["id"]);
      setValue("name", clinic["name"]);
      setValue("membershipType", clinic["membershipType"]);

      setValue("businessAddressLine1", clinic["businessAddressLine1"]);
      setValue("businessAddressLine2", clinic["businessAddressLine2"]);
      setValue("city", clinic["city"]);
      setValue("state", clinic["state"]);
      setValue("zip", clinic["zip"]);
      setValue("county", clinic["county"]);

      setValue(
        "isMailingAddressDifferent",
        clinic["isMailingAddressDifferent"]
      );
      setValue("mailingAddressLine1", clinic["mailingAddressLine1"]);
      setValue("mailingAddressLine2", clinic["mailingAddressLine2"]);
      setValue("mailingCity", clinic["mailingCity"]);
      setValue("mailingState", clinic["mailingState"]);
      setValue("mailingZip", clinic["mailingZip"]);

      setValue("phone", clinic["phone"]);
      setValue("fax", clinic["fax"]);
      setValue("email", clinic["email"]);
      setValue("website", clinic["website"]);
      setValue("notes", clinic["notes"]);

      if (clinic["designations"]) {
        clinic["designations"].forEach((option) => {
          const date = option["designationDate"]
            ? option["designationDate"].split("T")[0]
            : null;

          setValue(`designations.${option.designation.id}.selected`, true);
          setValue(`designations.${option.designation.id}.designation`, option.designation);
          setValue(`designations.${option.designation.id}.designationId`, option.designation.id);
          setValue(
            `designations.${option.designation.id}.hrsaGrantId`,
            option["hrsaGrantId"]
          );
          setValue(
            `designations.${option.designation.id}.designationDate`,
            date
          );
          setValue(
            `designations.${option.designation.id}.previouslyKnownAs`,
            option["previouslyKnownAs"]
          );

          if (option.designation.id === 1) {
            setDentalOnlyChecked(true);
          }
        });

        setUserInfo({
          designations: clinic["designations"]
            ? clinic["designations"].map((e) => e.designation.name)
            : [],
        });
      }
    }
  }, [clinic, setValue, setUserInfo, setDentalOnlyChecked]);

  return (
    showClinicInformation && (
      <Container>
        {canView &&(
        <Form
          onSubmit={handleSubmit(async (data) => await handleOnSubmit(data))}
        >
          <Row>
            <Col>
              <Row className="mb-4 mt-4 text-center">
                <Col>
                  <h3>
                    {watch("name")
                      ? `${watch("name")} Information`
                      : "[Clinic Name****] Information"}
                  </h3>
                </Col>
              </Row>

              <FormGroup row className="mb-4">
                <Label for="clinicName" sm={3} className="small-label-inline">
                  Clinic Name
                </Label>
                <Col md={9}>
                  <Input
                    id="clinicName"
                    type="text"
                    innerRef={nameRef}
                    {...nameRegisterProps}
                    required
                    disabled={isClinicDataSubmitted}
                  />
                  <FormFeedback>Clinic Name Required.</FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row className="mb-4">
                <Label md={3} className="small-label-inline">
                  Designation:
                </Label>
                <Col md={"auto"}>
                  {permissions["Designation"]?.map((option, index) => {
                    const selected =
                      clinic &&
                      clinic["designations"] &&
                      clinic["designations"].find(
                        (x) => x["designation"].id === option.id
                      );

                    return (
                      selected && (
                        <Label
                          key={index}
                          className={"small-label-inline col-form-label mx-1"}
                        >
                          {option.description},{" "}
                        </Label>
                      )
                    );
                  })}
                </Col>
                {!showDesignationChange && (
                  <Col md={"auto"}>
                    <Button
                      color="primary"
                      type="button"
                      className=""
                      onClick={handleDesignationRequestChange}
                    >
                      Request Change
                    </Button>
                  </Col>
                )}
              </FormGroup>

              {showDesignationChange &&
                permissions["Designation"]?.map((option, index) => {
                  const { ref: optionRef, ...optionRegisterProps } = register(
                    `designations.${option.id}.selected`,
                    { required: false }
                  );
                  const { ref: hrsaGrantIdRef, ...hrsaGrantIdRegisterProps } =
                    register(`designations.${option.id}.hrsaGrantId`, {
                      required: false,
                    });
                  const {
                    ref: designationDateRef,
                    ...designationDateRegisterProps
                  } = register(`designations.${option.id}.designationDate`, {
                    required: false,
                  });
                  const {
                    ref: previouslyKnownAsRef,
                    ...previouslyKnownAsRegisterProps
                  } = register(`designations.${option.id}.previouslyKnownAs`, {
                    required: false,
                  });

                  setValue(
                    `designations.${option.id}.designation`,
                    option.id
                  );

                  const lastItem =
                    permissions["Designation"].length - 1 === index;

                  return (
                    <React.Fragment key={option.id}>
                      {index === 0 && (
                        <Row>
                          <Col md={12} className={"text-center"}>
                            <Label className="small-label-inline col-form-label">
                              Designation Change
                            </Label>
                          </Col>
                        </Row>
                      )}

                      <FormGroup check className={`${lastItem ? "mb-4" : ""}`}>
                        <Input
                          id={option.name}
                          name={option.name}
                          type="checkbox"
                          innerRef={optionRef}
                          disabled={
                            (dentalOnlyChecked && option.id !== 1) ||
                            isClinicDataSubmitted
                          }
                          {...optionRegisterProps}
                          onChange={handleCheckOption}
                        />{" "}
                        <Label check className="check-label">
                          {option.description}
                        </Label>
                        {userInfo.designations.find((e) => e === option.name) &&
                          (option.name === "FQHC_DSG" ||
                            option.name === "FQHCLAL_DSG") && (
                            <Row>
                              <Label
                                for={`hrsa-${option.name}`}
                                sm={3}
                                className="small-label-inline"
                              >
                                HRSA Grant Id:
                              </Label>
                              <Col sm={3}>
                                <Input
                                  id={`hrsa-${option.name}`}
                                  name={`hrsa-${option.name}`}
                                  type="text"
                                  innerRef={hrsaGrantIdRef}
                                  {...hrsaGrantIdRegisterProps}
                                  disabled={isClinicDataSubmitted}
                                />
                              </Col>
                            </Row>
                          )}
                        {userInfo.designations.find(
                          (e) => e === option.name
                        ) && (
                          <>
                            <Row>
                              <Label
                                for={`date-${option.name}`}
                                sm={3}
                                className="small-label-inline"
                              >
                                Date of Designation:
                              </Label>
                              <Col sm={3}>
                                <Input
                                  id={`date-${option.name}`}
                                  name={`date-${option.name}`}
                                  type="date"
                                  innerRef={designationDateRef}
                                  {...designationDateRegisterProps}
                                  disabled={isClinicDataSubmitted}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Label
                                for={`previous-${option.name}`}
                                sm={3}
                                className="small-label-inline"
                              >
                                Previously Known As:
                              </Label>
                              <Col sm={3}>
                                <Input
                                  id={`previous-${option.name}`}
                                  name={`previous-${option.name}`}
                                  type="text"
                                  innerRef={previouslyKnownAsRef}
                                  {...previouslyKnownAsRegisterProps}
                                  disabled={isClinicDataSubmitted}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                      </FormGroup>
                    </React.Fragment>
                  );
                })}

              {showDesignationChange && (
                <Row row className="mb-4">
                  <Col md={9} className={"col-text-right"}>
                    <Button
                      color="secondary"
                      className="mx-2"
                      onClick={handleCancelDesignationChange}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      onClick={handleSubmitDesignationChange}
                    >
                      Submit Amendment
                    </Button>
                  </Col>
                </Row>
              )}

              <FormGroup row className="mb-4">
                <Label
                  className="small-label-inline"
                  for="clinicBusinessAddress1"
                  sm={3}
                >
                  Business Address Line 1
                </Label>
                <Col md={9}>
                  <Input
                    id="clinicBusinessAddress1"
                    type="text"
                    innerRef={businessAddressLine1Ref}
                    {...businessAddressLine1RegisterProps}
                    disabled={isClinicDataSubmitted}
                  />
                </Col>
              </FormGroup>

              <FormGroup row className="mb-4">
                <Label
                  className="small-label-inline"
                  for="clinicBusinessAddress2"
                  sm={3}
                >
                  Business Address Line 2
                </Label>
                <Col md={9}>
                  <Input
                    id="clinicBusinessAddress2"
                    type="text"
                    innerRef={businessAddressLine2Ref}
                    {...businessAddressLine2RegisterProps}
                    disabled={isClinicDataSubmitted}
                  />
                </Col>
              </FormGroup>

              <FormGroup row className="mb-4">
                <Label className="small-label-inline" for="clinicCity" sm={3}>
                  City
                </Label>
                <Col md={5}>
                  <Input
                    id="clinicCity"
                    type="text"
                    innerRef={cityRef}
                    {...cityRegisterProps}
                    disabled={isClinicDataSubmitted}
                  />
                </Col>
              </FormGroup>

              <FormGroup row className="mb-4">
                <Label className="small-label-inline" for="clinicState" sm={3}>
                  State
                </Label>
                <Col md={5}>
                  <Input
                    id="clinicState"
                    type="select"
                    innerRef={stateRef}
                    {...stateRegisterProps}
                    defaultValue={-1}
                    disabled={isClinicDataSubmitted}
                  >
                    <option value={-1} hidden disabled>
                      -- Select State--
                    </option>
                    {unitedStatesStates.map((state) => {
                      return (
                        <option key={state.name} value={state.name}>
                          {state.name}
                        </option>
                      );
                    })}
                  </Input>
                </Col>
              </FormGroup>

              <FormGroup row className="mb-4">
                <Label className="small-label-inline" for="clinicZip" sm={3}>
                  Zip
                </Label>
                <Col md={5}>
                  <Input
                    id="clinicZip"
                    name="zip"
                    type="number"
                    innerRef={zipRef}
                    {...zipRegisterProps}
                    disabled={isClinicDataSubmitted}
                  />
                </Col>
              </FormGroup>

              <FormGroup row className="mb-4">
                <Label className="small-label-inline" for="clinicCounty" sm={3}>
                  County
                </Label>
                <Col md={5}>
                  <Input
                    id="clinicCounty"
                    type="text"
                    innerRef={countyRef}
                    {...countyRegisterProps}
                    disabled={isClinicDataSubmitted}
                  />
                </Col>
              </FormGroup>

              <FormGroup row className="mb-4">
                <Label
                  className="small-label-inline"
                  for="mailingAddress"
                  sm={4}
                >
                  Is the mailing address the same as the business address?
                </Label>
                <Col sm={1} className="d-flex align-items-center">
                  <Input
                    id="mailingAddressYes"
                    className="me-2"
                    type="checkbox"
                    onChange={() => handleIsSameMailingAddress(true)}
                    checked={
                      watch("isMailingAddressDifferent") !== undefined &&
                      watch("isMailingAddressDifferent")
                    }
                    disabled={isClinicDataSubmitted}
                  />
                  <Label
                    className="small-label-inline"
                    check
                    for="mailingAddressYes"
                    sm={3}
                  >
                    Yes
                  </Label>
                </Col>
                <Col sm={1} className="d-flex align-items-center">
                  <Input
                    id="mailingAddressNo"
                    className="me-2"
                    name="mailingAddressNo"
                    type="checkbox"
                    onChange={() => handleIsSameMailingAddress(false)}
                    checked={
                      watch("isMailingAddressDifferent") !== undefined &&
                      !watch("isMailingAddressDifferent")
                    }
                    disabled={isClinicDataSubmitted}
                  />{" "}
                  <Label
                    className="small-label-inline"
                    check
                    for="mailingAddressNo"
                    sm={2}
                  >
                    No
                  </Label>
                </Col>
              </FormGroup>

              {watch("isMailingAddressDifferent") !== undefined &&
                !watch("isMailingAddressDifferent") && (
                  <>
                    <FormGroup row className="mb-4">
                      <Label
                        className="small-label-inline"
                        for="clinicMailingAddress1"
                        sm={3}
                      >
                        Mailing Address Line 1
                      </Label>
                      <Col md={9}>
                        <Input
                          id="clinicMailingAddress1"
                          type="text"
                          innerRef={mailingAddressLine1Ref}
                          {...mailingAddressLine1RegisterProps}
                          disabled={isClinicDataSubmitted}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row className="mb-4">
                      <Label
                        className="small-label-inline"
                        for="clinicMailingAddress2"
                        sm={3}
                      >
                        Mailing Address Line 2
                      </Label>
                      <Col md={9}>
                        <Input
                          id="clinicMailingAddress2"
                          type="text"
                          innerRef={mailingAddressLine2Ref}
                          {...mailingAddressLine2RegisterProps}
                          disabled={isClinicDataSubmitted}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row className="mb-4">
                      <Label
                        className="small-label-inline"
                        for="clinicMailingCity"
                        sm={3}
                      >
                        City
                      </Label>
                      <Col md={4}>
                        <Input
                          id="clinicMailingCity"
                          type="text"
                          innerRef={mailingCityRef}
                          {...mailingCityRegisterProps}
                          disabled={isClinicDataSubmitted}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row className="mb-4">
                      <Label
                        className="small-label-inline"
                        for="clinicMailingState"
                        sm={3}
                      >
                        State
                      </Label>
                      <Col md={4}>
                        <Input
                          id="clinicMailingState"
                          type="select"
                          innerRef={mailingStateRef}
                          {...mailingStateRegisterProps}
                          defaultValue={-1}
                          disabled={isClinicDataSubmitted}
                        >
                          <option value={-1} hidden disabled>
                            -- Select State--
                          </option>
                          {unitedStatesStates.map((state) => {
                            return (
                              <option key={state.name} value={state.name}>
                                {state.name}
                              </option>
                            );
                          })}
                        </Input>
                      </Col>
                    </FormGroup>

                    <FormGroup row className="mb-4">
                      <Label
                        className="small-label-inline"
                        for="clinicMailingZip"
                        sm={3}
                      >
                        Zip
                      </Label>
                      <Col md={4}>
                        <Input
                          id="clinicMailingZip"
                          type="number"
                          innerRef={mailingZipRef}
                          {...mailingZipRegisterProps}
                          disabled={isClinicDataSubmitted}
                        />
                      </Col>
                    </FormGroup>
                  </>
                )}

              <FormGroup row className="pt-4 mb-4">
                <Label className="small-label-inline" for="clinicPhone" sm={3}>
                  Phone
                </Label>
                <Col md={4}>
                  <Input
                    id="clinicPhone"
                    type="tel"
                    innerRef={phoneRef}
                    {...phoneRegisterProps}
                    disabled={isClinicDataSubmitted}
                  />
                </Col>
              </FormGroup>

              <FormGroup row className="mb-4">
                <Label className="small-label-inline" for="clinicFax" sm={3}>
                  Fax
                </Label>
                <Col md={4}>
                  <Input
                    id="clinicFax"
                    type="tel"
                    innerRef={faxRef}
                    {...faxRegisterProps}
                    disabled={isClinicDataSubmitted}
                  />
                </Col>
              </FormGroup>

              <FormGroup row className="mb-4">
                <Label className="small-label-inline" for="clinicEmail" sm={3}>
                  Email
                </Label>
                <Col md={9}>
                  <Input
                    id="clinicEmail"
                    type="email"
                    innerRef={emailRef}
                    placeholder="example@example.com"
                    invalid={errors.email !== undefined}
                    {...emailRegisterProps}
                    disabled={isClinicDataSubmitted}
                  />
                  <FormFeedback>Email not in the expected format.</FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row className="mb-4">
                <Label
                  className="small-label-inline"
                  for="clinicWebsite"
                  sm={3}
                >
                  Website
                </Label>
                <Col md={9}>
                  <Input
                    id="clinicWebsite"
                    type="url"
                    innerRef={websiteRef}
                    {...websiteRegisterProps}
                    disabled={isClinicDataSubmitted}
                  />
                  <FormFeedback>
                    WebSite not in the expected URL format.
                  </FormFeedback>
                </Col>
              </FormGroup>


              <FormGroup row className="mb-4">
                <Label
                  className="small-label-inline"
                  for="clinicNotes"
                  sm={3}
                >
                  Notes
                </Label>
                <Col md={9}>
                  <Input
                    id="clinicNotes"
                    type="textarea"
                    innerRef={notesRef}
                    {...notesRegisterProps}
                    disabled={isClinicDataSubmitted}
                  />
                </Col>
              </FormGroup>
             
              <DesignationChangeRequest shouldRefresh={true} designations={clinic["designationAmendments"]} />

              <Row className="my-5">
                <Col className="d-flex justify-content-end align-items-baseline">
                  <Label className="small-label-inline col-form-label px-3 py-5 link-primary" for="next-page">
                    User Accounts{" "}
                  </Label>
                  {canUpdate && (
                  <Button
                    id="next-page"
                    color="primary"
                    type="submit"
                    className="btn-stepper-next"
                  >
                    <BsFillArrowRightCircleFill size={40} />
                  </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
        )}
      </Container>
    )
  );
};

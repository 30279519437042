import { useHistory, useLocation } from "react-router-dom";
import "survey-core/defaultV2.min.css";
import "survey-core/survey.css";
import { StylesManager, Model, FunctionFactory } from "survey-core";
import { Survey } from "survey-react-ui";
import {
  patientDataJSON,
  staffingAndUtilizationJSON,
  dataSubmissionJSON,
  dataMeasuresJSON,
  clinicalMeasuresJSON,
  financialMeasuresJSON,
  apiClient,
  ApiEndpoints,
  ApiRoutes,
  apiGetCurrentSurveyTemplate,
} from "utilities";
import React, { useEffect, useState } from "react";
import { ReviewClinicInformation } from "./ReviewClinicInformation";
import { useUserContext } from "contexts";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { manualDataJSON } from "utilities/surveys/manualDataJSON";
import { UDSDataUpload } from "components/ClinicDataEntry/UDSDataUpload";
import { Button, Col, FormGroup, Row } from "reactstrap";
import { trackPromise } from "react-promise-tracker";
import "./Review.scss";
import { AmendmentBanner } from "components/Survey/AmendmentBanner";
import {
  formatAsUSDollar,
  getSavedDataEntries,
  roundTo,
} from "../../utilities/surveys/functions";

const mapSurvey = (location) => {
  switch (location) {
    case "PatientData":
      return patientDataJSON;
    case "StaffingAndUtilization":
      return staffingAndUtilizationJSON;
    case "ClinicalMeasures":
      return clinicalMeasuresJSON;
    case "FinancialMeasures":
      return financialMeasuresJSON;
    case "DataMeasures":
      return dataMeasuresJSON;
    case "DataSubmission":
      return dataSubmissionJSON;
    case "ManualDataEntry":
        return manualDataJSON;
    default:
      return null;
  }
};

export const Review = ({surveySections, clinicData}) => {
  const location = useLocation();
  const history = useHistory();
  const routePath =
    location.pathname.indexOf("#") > -1
      ? location.pathname
      : location.pathname + location.hash;
  const pageToReview =
    routePath.split("#")[1] === undefined
      ? "ClinicDetails"
      : routePath.split("#")[1];

  const {
    formState: { errors },
    register,
    setValue,
    watch,
    handleSubmit,
  } = useForm();

  const [clinic, setClinic] = useState({});

  const userContext = useUserContext();
  const clinicId = userContext?.clinicId;
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [surveyJSData, setSurveyJSData] = useState();
  const [surveyJSON, setSurveyJSON] = useState();
  const [amendmentReason, setAmendmentReason] = useState();
  
  const getClinicNDHStatus = (pageToReview) => {
    const section = window.localStorage.getItem(
      `${pageToReview}_ClinicNDHData`
    );

    if (section) {
      return JSON.parse(section)?.Status;
    }
    
    return null;
  };

  const [vStatus, setVstatus]= useState(getClinicNDHStatus(pageToReview))

  FunctionFactory.Instance.register("roundTo", roundTo);
  FunctionFactory.Instance.register("formatAsUSDollar", formatAsUSDollar);
  FunctionFactory.Instance.register("getSavedDataEntries", getSavedDataEntries,true);

  const setClinicNDHStatus = (e) => {
    const sectionStatus = {
      Status: e,
      Section: pageToReview,
    };

    window.localStorage.setItem(
      `${pageToReview}_ClinicNDHData`,
      JSON.stringify(sectionStatus)
    );
    setVstatus(e);
  }


  

  useEffect(() => {
    if (clinicId) {
      const url = `${ApiRoutes.AccountManagement.ClinicDetails.replace(
        ":id",
        clinicId
      )}`;
      trackPromise(apiClient
        .get(url)
        .then((response) => {
            setClinic(response.data);
        })
        .catch((resp) => {}));
    }
  }, [clinicId]);

  useEffect(() => {
    if (clinic) {
      setValue("id", clinic["id"]);
      setValue("name", clinic["name"]);
    }
  }, [clinic, setValue]);

  useEffect(() => {
    const currentYear=new Date().getFullYear() - 1;
    const url = ApiEndpoints.ClinicManagement.ClinicPatientData.GetById.replace("{clinic}", userContext?.clinicId).replace("{section}", pageToReview).replace("{year}",currentYear.toString());
    if(!isDataLoaded || clinicId!== userContext?.clinicId )
    {
      setIsDataLoaded(true);
      apiGetCurrentSurveyTemplate(pageToReview,mapSurvey(location)).then((response)=>
      {
      if(response?.template)
        setSurveyJSON(response.template);
      else
        // @ts-ignore
        setSurveyJSON(mapSurvey(pageToReview));
      })
      trackPromise(apiClient
        .get(url).then(
          (response) => {
            if(response?.data!==null)
            {
              setSurveyJSData(response.data.data)
              setAmendmentReason(response.data.amendmentReason)
              if(response?.data.status)
               setClinicNDHStatus(response.data.status)
            }
            else
            {
              window.localStorage.removeItem(`${pageToReview}_ClinicNDHData`);
            }

          }
        )
        .catch((error) => {
          window.localStorage.removeItem(`${pageToReview}_ClinicNDHData`);
        }));
    }
  },[pageToReview, isDataLoaded]);

  const insertData = async (survey, showModal = undefined) => {

    const result = survey.data;
    survey.getAllQuestions().forEach(function(question) {
      if(result[question.name] === undefined) {
        result[question.name] = null; 
      }
      if(question.page?.name !== survey.currentPage?.name)
      {
        delete result[question.name];
      }
      });

    const odssection = {
      ClinicId: userContext?.clinicId,
      DataExtension: JSON.stringify({
        // @ts-ignore
        Questions:surveyJSON?.pages.find(p=>p.name===survey.currentPage.name),
        Answers: result,
        // @ts-ignore
        Version: surveyJSON?.version,
        SubmitionDate: new Date(),
        ClinicId: userContext?.clinicId,
      }),
      OdsSection: survey.currentPage.name ,
    };

    const data = {
      clinicId: userContext?.clinicId,
      Data: JSON.stringify(survey.data),
      Status: vStatus,
      Section: pageToReview,
      Year: new Date().getFullYear() -1,
      ClinicNDHSections: [odssection],
    };

    const url = "ClinicPatientData";
    await trackPromise(apiClient
      .put(url, data, {
        // @ts-ignore
        toastConfig: {
          showSuccess: showModal?true:false,
          successMessage: "Survey saved successfully",
        },
      })
      .then(
        () => {
          setIsDataLoaded(false);
        }
      )
      .catch((error) => console.log(error.response.request._response)));
  };

  const { ref: nameRef, ...nameRegisterProps } = register("name", {
    required: true,
  });

  const saveSurveyData = (survey,pageToReview) => {
    const surveyData = survey.data;
    surveyData.pageNo = survey.currentPageNo;

    // const sectionStatus = {
    //   Status: "InReview",
    //   Section: pageToReview,
    // };

    // window.localStorage.setItem(
    //   `${pageToReview}_ClinicNDHData`,
    //   JSON.stringify(sectionStatus)
    // );
  };

  const sendNotification = async (message = undefined, url_ =undefined) => {
    var today = new Date();
    var nextWeek = new Date(new Date().getTime()+(7*24*60*60*1000)).toISOString();
    const notificationData ={
      "id": "00000000-0000-0000-0000-000000000000",
      "type": "Actionable",
      "messageBody": message,
      "url": url_,
      "createdBy": userContext?.user.userID,
      "startDate": today.toISOString(),
      "endDate": nextWeek,
      "clinicIds":  userContext?.clinicId,
      "usersIds": "tharkness@communitycareks.org, tkennedy@communitycareks.org",
    };
    
    const url = `${ApiEndpoints.AccountManagement.Notifications.CreateUserNotification}`;
    await trackPromise(apiClient
      .put(url, notificationData,
      )
      .then()
      .catch((error) => console.log(error)));
  };

  StylesManager.applyTheme("defaultV2");
  var defaultThemeColors = StylesManager.ThemeColors["default"];
  defaultThemeColors["$main-color"] = "#7ff07f";
  defaultThemeColors["$main-hover-color"] = "#6fe06f";
  defaultThemeColors["$text-color"] = "#4a4a4a";
  defaultThemeColors["$header-color"] = "#7ff07f";
  defaultThemeColors["$header-background-color"] = "#4a4a4a";
  defaultThemeColors["$body-container-background-color"] = "#f8f8f8";
  StylesManager.applyTheme("defaultV2");

  const survey = new Model(surveyJSON);
  survey.setVariable("clinicId", userContext?.clinicId);
  survey.showTOC =false;

  history.listen(() => { setIsDataLoaded(false)})
  const validateHasUdsUpload = (survey, { data, errors, complete }) => {
    trackPromise(apiClient
      .get(`${ApiEndpoints.ClinicManagement.ClinicPatientData.UdsFileUpload}Date/${clinicId}`)
      .then((response) => {
        if (response.status === 200 && response.data !== "") {
          complete();
        } else {
          errors["udsDataUpload"] = "UDS file required. Please upload a UDS file to proceed with data submission."
          complete();
        }
      }));
  }

  survey.sendResultOnPageNext = true;
  var prevData = surveyJSData;//window.localStorage.getItem(pageToReview) || null;
  if (prevData) {
    var data = JSON.parse(prevData);
    survey.data = data;
  }
  survey.showPreviewBeforeComplete = "showAllQuestions";
  survey.showPreview();
  survey.mode = "display";

  survey.showPrevButton= false;
  survey.pageNextText = "Save";
  survey.previewText = "Finish Edit";

  if(vStatus === 'ValidationPass')
    survey.completeText='Complete Review';

  if (pageToReview === "DataSubmission") {
    const data = {
      clinicId: userContext?.clinicId,
      Year: new Date().getFullYear() -1,
      createdBy: userContext?.user.userID,
      entryType: window.location.pathname === '/ClinicDataEntry/DataEntryReview'?'ManualDataEntry':'FullDataEntry',
    };
    survey.mode = "edit";
    survey.setVariable("currentYear", new Date().getFullYear() -1);
    survey.setVariable("clinicName", watch("name"));

    if (window.location.pathname === "/ClinicDataEntry/DataEntryReview") {
      survey.onServerValidateQuestions.add(validateHasUdsUpload);
    }
      
    survey.onComplete.add((survey, options) => {
      insertData(survey,false); 
      trackPromise(apiClient
      .post(ApiEndpoints.ClinicManagement.ClinicPatientData.DataSubmission, data, {
        // @ts-ignore
        toastConfig: {
          showSuccess: true,
          successMessage: "Data Submitted successfully!",
        },
      })
      .then((res) => {
        const dataStatus = vStatus ==='ValidationPass'?'ReadyForETL':'Submitted';
        for (let section of surveySections) {
          const status = {
            Status: dataStatus,
            Section: section,
          };
          window.localStorage.setItem(
            `${section}_ClinicNDHData`,
            JSON.stringify(status)
          );
        }
        sendNotification(dataStatus ==='Submitted'?'Clinic ' + watch("name") + ' data has been submitted for reporting year ' + (new Date().getFullYear() -1) :'Clinic ' + watch("name") + ' data has been completed for reporting  ' + (new Date().getFullYear()-1));
        setTimeout(function(){history.go(0)},5000);
      })
      .catch((error) => {
        toast.error('An error has occured, please try again. If the problem persists, contact your system administrator.');
        console.log(error);
        return;
      }));
    });
  }
  else
  {
    survey.onComplete.add((survey) => {
      saveSurveyData(survey,pageToReview);
      insertData(survey,true);
      survey.showPreview();
    });
  }

  survey.onPartialSend.add((survey) => {
    saveSurveyData(survey,pageToReview);
    insertData(survey,false);
    survey.showPreview();
  });

  survey.onShowingPreview.add((survey) => {
    saveSurveyData(survey,pageToReview);
    insertData(survey,false);
  })
  survey.registerFunctionOnPropertyValueChanged(
    "isShowingPreview",
    () => {
      survey.mode='edit';
    }
  ) 
  if (vStatus === 'Submitted') {
    return (
      <Row className="m-5">
        <Col className="mx-5">
          <b>{watch("name")}</b> data has been submitted for reporting year <b>{new Date().getFullYear() -1}</b>. If any submitted data requires correction, you may submit an amendment request for that data from the data section.
        </Col>
      </Row>
    )
  }
  else if (vStatus === 'ETLComplete') {
    return (
      <Row className="m-5">
        <Col className="mx-5">
          <b>{watch("name")}</b> data has been completed for reporting year <b>{new Date().getFullYear() -1}</b>. 
        </Col>
      </Row>
    )
  } 
  else if (pageToReview === "ClinicDetails") {
      return <ReviewClinicInformation clinicData={clinicData} />
  } else if (pageToReview === "UDSDataUpload") {
    return <UDSDataUpload isReview={true} />
  } else {
    return (
      <>
      <AmendmentBanner status={vStatus} reason={amendmentReason}/>
        <Survey model={survey} className="data-entry-survey" />
      </>
    )
  }
};
